





































import { Component, Vue } from 'vue-property-decorator';
import { GetMessageDetail } from '@/http/api/ArticleApi';
import '@/assets/style/merge.less';

@Component
export default class MessageDeteil extends Vue {
  private loading: boolean = false;
  private articleData: any = '';

  private created() {
    this.getDetail((this.$route.query.id as string));
  }
  // 获取文章数据列表
  private getDetail(id: string): void {
    this.loading = true;
    GetMessageDetail({ id }).then((res) => {
      this.loading = false;
      if (res.code === '000') {     
        const data = res.result;
        this.articleData = res.result;
      }
    });
  }
}
